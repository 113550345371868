import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/icons',
          name: 'icons',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue')
        },
        {
          path: '/profil',
          name: 'profil',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/maps',
          name: 'maps',
          component: () => import(/* webpackChunkName: "demo" */ './views/Maps.vue')
        },
        {
          path: '/tables',
          name: 'rendez-vous',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue')
        },
        {
          path: '/patient',
          name: 'patients',
          component: () => import(/* webpackChunkName: "demo" */ './views/patient.vue')
        },
        {
          path: '/guest',
          name: 'Visiteurs',
          component: () => import(/* webpackChunkName: "demo" */ './views/Guest.vue')
        },
        {
          path: '/contact',
          name: 'contacts',
          component: () => import(/* webpackChunkName: "demo" */ './views/contact.vue')
        },
        {
          path: '/offre',
          name: 'offres',
          component: () => import(/* webpackChunkName: "demo" */ './views/Offre/index.vue')
        },
        {
          path: '/avis',
          name: 'avis',
          component: () => import(/* webpackChunkName: "demo" */ './views/Avis.vue')
        },
        {
          path: '/changePassword',
          name: 'Changer mot de passe',
          component: () => import(/* webpackChunkName: "demo" */ './views/ChangePassword.vue')
        },
        {
          path: '/chat',
          name: 'chat',
          component: () => import(/* webpackChunkName: "demo" */ './views/Chat.vue')
        },

      ],
      beforeEnter:(to,from,next) => {
        if(!store.getters['doctor/isLoggedIn'] && !to.query.docId)
          next({
            path:'/login'
          })
        else
          next()
      }
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        }
      ],
      beforeEnter:(to,from,next)=>{
        if(store.getters['doctor/isLoggedIn'])
          next({
            path:'/dashboard'
          })
        else next()
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
