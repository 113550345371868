import { io } from 'socket.io-client'
import Vue from 'vue'
export default {
  connectSocket({ commit, rootState, dispatch }) {
    const socket = io("https://algedoc-backend.herokuapp.com/");

    socket.on('connect', () => {
      socket.emit('joinConnectedUsers', { userId: rootState.doctor.user._id })
      console.log("socket connected");
      socket.on('appointmentReceived', () => {
        dispatch('notification/getNotifications',null,{root:true})
      })
      socket.on('accountVerified',(notif)=>{
        dispatch('notification/getNotifications',null,{root:true})
        Vue.$toast.open({
          message: notif.description,
          type: "success",
        });
      })
    })

    commit('SET_SOCKET', socket)
  },

  closeSocket({ commit, state, rootState }) {
    if (state.socket) {
      state.socket.emit('disconnected', { userId: rootState.doctor.userId })
      console.log("closing socket");
      state.socket.close()
      commit('SET_SOCKET', null)
    }
  },

  acceptAppointmentNotification({ state, rootState }, { patientId }) {
    state.socket.emit('acceptAppointment', { patientId, doctorId: rootState.doctor.userId })
  },

  declineAppointmentNotification({ state, rootState }, { patientId }) {
    state.socket.emit('declineAppointment', { patientId, doctorId: rootState.doctor.userId })
  }

}