var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Argon","title":"Argon"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.dashboard'),
          icon: 'ni ni-tv-2',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.appointments'),
          icon: 'ni ni-bullet-list-67',
          path: '/tables',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.patients'),
          icon: 'ni ni-single-02 ',
          path: '/patient',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.chat'),
          icon: 'ni ni-email-83 ',
          path: '/chat',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.offers'),
          icon: 'ni ni-credit-card',
          path: '/offre',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.doctorProfile'),
          icon: 'ni ni-single-02 ',
          path: '/profil',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.changePassword'),
          icon: 'ni ni-single-02 ',
          path: '/changePassword',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sideNav.opinion'),
          icon: 'ni ni-single-02 ',
          path: '/avis',
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }