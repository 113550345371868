<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <!-- <div class="form-group mb-0">
                <base-input placeholder="Search"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search">
                </base-input>
            </div> -->
    </form>

    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <div class="media-body ml-2 d-none d-lg-block">
              {{ $t("nav.language") }}
            </div>
          </div>

          <template>
            <ul class="lang-dropdown">
              <li @click="changeLanguage('fr')" class="dropdown-item">
                <img
                  src="../../public/img/lang-fr.png"
                  height="40"
                  style="margin-right: 5%"
                />
                {{ $t("languages.french") }}
              </li>
              <div class="dropdown-divider"></div>
              <li @click="changeLanguage('en')" class="dropdown-item">
                <img
                  src="../../public/img/lang-en.jpg"
                  height="22"
                  style="margin-right: 5%"
                />

                {{ $t("languages.english") }}
              </li>
              <div class="dropdown-divider"></div>
              <li @click="changeLanguage('tr')" class="dropdown-item">
                <img
                  src="../../public/img/lang-tr.svg"
                  height="30"
                  style="margin-right: 5%"
                />

                {{ $t("languages.turkish") }}
              </li>
              <div class="dropdown-divider"></div>
              <li @click="changeLanguage('ar')" class="dropdown-item">
                <img
                  src="../../public/img/lang-ar.png"
                  height="30"
                  style="margin-right: 5%"
                />

                {{ $t("languages.arabic") }}
              </li>
            </ul>
          </template>
        </base-dropdown>
      </li>
    </ul>

    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0 notif-dropdown">
          <div class="media align-items-center" slot="title">
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold"
                ><i class="ni ni-bell-55" style="font-size: 30px">
                  <b-badge
                    style="padding-left: 0"
                    class="badge-circle badge-md badge-floating border-white"
                    >({{ notificationCount }})</b-badge
                  ></i
                ></span
              >
            </div>
          </div>

          <template>
            <ul
              class="notif-dropdown"
              v-if="notifications && notifications.length"
            >
              <li
                class="dropdown-item"
                v-for="(notif, index) in notifications"
                :key="index"
                @click="openNotif(notif)"
              >
                {{ notif.description }}
                <div class="notif-seen" v-if="!notif.seen"></div>
                <div class="dropdown-divider"></div>
              </li>
            </ul>
            <ul class="notif-dropdown" v-else>
              <li class="dropdown-item">{{ $t("nav.emptyNotifications") }}</li>
            </ul>
          </template>
        </base-dropdown>
      </li>
    </ul>

    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle" v-if="!user.photo">
              <img
                alt="Image placeholder"
                src="https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
                v-if="user.gender == 'homme'"
              />
              <img
                alt="Image placeholder"
                src="https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png"
                v-else
              />
            </span>
            <span class="avatar avatar-sm rounded-circle" v-else>
              <img
                alt="Image placeholder"
                :src="`${$store.state.doctor.cloudinaryUrl}${user.photo}`"
              />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold"
                >{{ user.firstName }} {{ user.lastName }}</span
              >
            </div>
          </div>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">{{ $t("nav.welcome") }}</h6>
            </div>
            <router-link to="/profil" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>{{ $t("nav.profile") }}</span>
            </router-link>
            <!-- <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Settings</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-calendar-grid-58"></i>
                            <span>Activity</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-support-16"></i>
                            <span>Support</span>
                        </router-link> -->

            <div class="dropdown-divider"></div>
            <div @click="logout">
              <router-link to="#" class="dropdown-item">
                <i class="ni ni-user-run"></i>
                <span>{{ $t("nav.logout") }}</span>
              </router-link>
            </div>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.doctor.user;
    },

    notifications() {
      return this.sortByDate(this.$store.state.notification.notifications);
    },

    notificationCount() {
      return this.notifications.reduce(
        (acc, cur) => (!cur.seen ? acc + 1 : acc),
        0
      );
    },
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      this.$i18n.locale = lang;
      this.$store.commit("doctor/SET_LANGUAGE", lang);
    },
    sortByDate(array) {
      return array.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    },
    async openNotif(notification) {
      try {
        if (notification.title == "Rendez vous")
          await this.$router.replace("/tables");
      } catch (err) {
        console.log(err);
      }
      if (!notification.seen) {
        try {
          const index = this.notifications.findIndex(
            (notif) => notif._id == notification._id
          );
          const updatedNotif = await this.$store.dispatch(
            "notification/updateNotification",
            notification._id
          );
          this.notifications.splice(index, 1, updatedNotif);
          this.$store.commit("notification/SET_NOTIFS", this.notifications);
        } catch (err) {
          console.log(err);
        }
      }
    },

    async openExternalNotif(_id) {
      const notification = this.notifications.find((notif) => notif._id == _id);
      if (!notification.seen) {
        try {
          const index = this.notifications.findIndex(
            (notif) => notif._id == notification._id
          );
          const updatedNotif = await this.$store.dispatch(
            "notification/updateNotification",
            notification._id
          );
          this.notifications.splice(index, 1, updatedNotif);
          this.$store.commit("notification/SET_NOTIFS", this.notifications);
        } catch (err) {
          console.log(err);
        }
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("socket/closeSocket");
      this.$store.dispatch("doctor/logout");
      this.$router.replace("/login");
    },

    async getNotifications() {
      await this.$store.dispatch("notification/getNotifications");
    },
  },
  mounted() {
    this.getNotifications();
    const { notifId } = this.$route.query;
    if (notifId) this.openExternalNotif(notifId);
  },
};
</script>
<style>
.lang-dropdown {
  padding: 0;
}
.lang-dropdown li {
  cursor: pointer;
}

.notif-dropdown .dropdown-menu {
  left: -10vw !important;
}

.notif-dropdown .dropdown-menu ul {
  padding: 0 !important;
  max-height: 45vh;
  overflow: scroll;
}

.notif-dropdown .dropdown-item {
  min-width: 10vw !important;
  max-width: 50vw !important;
  white-space: pre-wrap !important;
  padding: 5px;
  cursor: pointer;
}

.notif-dropdown::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notif-dropdown {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.notif-seen {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
  display: inline-block;
}
</style>