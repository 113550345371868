import axios from 'axios'
export default {
  async createRoom() {
    try {
      const res = await axios.post(`/chatRooms/`)
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getChatRooms({ rootState }) {
    try {
      const res = await axios.get(`/chatRooms/${rootState.doctor.userId}`)
      return res.data.chatRooms
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendMessage(_, message) {
    try {
      const res = await axios.post(`/messages`, { message })
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getMessages(_, roomId) {
    try {
      const res = await axios.get(`/messages/room/${roomId}`)
      return res.data.messages
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendReaction(_, {messageId,reactions}) {
    try {
      const res = await axios.put(`/messages/message/${messageId}`,{reactions})
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },
  
  async updateMessage(_,{messageId,message}){
    try {
      const res = await axios.put(`/messages/message/${messageId}`,message)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async deleteMessage(_,{messageId}){
    try {
      const res = await axios.delete(`/messages/message/${messageId}`)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  }
}