<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="..." />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <!-- <base-dropdown class="nav-item" position="right">
            <template>
              <ul
                class="notif-dropdown"
                v-if="notifications && notifications.length"
              >
                <li
                  class="dropdown-item"
                  v-for="(notif, index) in notifications"
                  :key="index"
                  @click="openNotif(notif)"
                >
                  {{ notif.description }}
                  <div class="notif-seen" v-if="!notif.seen"></div>
                  <div class="dropdown-divider"></div>
                </li>
              </ul>
              <ul class="notif-dropdown" v-else>
                <li class="dropdown-item">
                  {{ $t("nav.emptyNotifications") }}
                </li>
              </ul>
            </template>
          </base-dropdown> -->
          <base-dropdown class="nav-item" position="right">
            <a slot="title" class="nav-link" href="#" role="button">
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img
                    alt="Image placeholder"
                    src="https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
                  />
                </span>
              </div>
            </a>

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profil" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>{{ $t("nav.profile") }}</span>
            </router-link>

            <router-link to="#" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>{{ $t("nav.logout") }}</span>
            </router-link>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <!--Heading-->
        <!-- <h6 class="navbar-heading text-muted">Documentation</h6> -->
        <!--Navigation-->
        <ul class="navbar-nav mb-md-3">
          <!-- <li class="nav-item">
                        <a class="nav-link"
                           href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/components/alerts.html">
                            <i class="ni ni-ui-04"></i> Utilisation
                        </a>
                    </li> -->
          <!-- <li class="nav-item">
                        <a class="nav-link"
                           href="https://demos.creative-tim.com/vue-argon-dashboard/documentation">
                            <i class="ni ni-spaceship"></i> Getting started
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/foundation/colors.html">
                            <i class="ni ni-palette"></i> Foundation
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/components/alerts.html">
                            <i class="ni ni-ui-04"></i> Components
                        </a>
                    </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "logoN.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style >
.navbar-vertical .navbar-collapse:before {
  margin-bottom: 0rem !important;
}
.navbar-brand {
  padding-bottom: 0rem !important;
}
.navbar-brand-img {
  max-height: 150px !important;
  max-width: 150px !important;
  margin-right: 5%;
  margin-top: 2%;
}
</style>