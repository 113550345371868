<template>
  <div id="app">
    <notifications></notifications>
    <router-view />
  </div>
</template>

<script>
import { io } from "socket.io-client";

export default {
  data: () => ({
    socket: null,
    doctor: null,
  }),
  methods: {
    async getDoctor() {
      await new Promise((r) => setTimeout(r, 250));
      let { docId } = this.$route.query;

      try {
        this.doctor = await this.$store.dispatch("doctor/getDoctor", docId);
        this.initSocket();
      } catch (err) {
        this.$router.replace("/login");
      }
    },

    initSocket() {
      this.$store.dispatch("socket/connectSocket");
      this.$store.state.socket.socket.on("appointmentReceived", (notif) => {
        console.log("appointmentReceived");
        this.$toast.open({
          message: notif.description,
          type: "success",
          // position: "top-right",
        });
      });
    },
  },
  async mounted() {
    await this.getDoctor();
  },

  beforeDestroy() {
    this.$store.dispatch("socket/closeSocket");
  },
};
</script>