import axios from "axios";

export default {

  async login({ commit }, payload) {
    try {
      const res = await axios.post(`/user/login`, payload)
      if(res.data.user.role != "doctor")
        throw new Error('not a doctor')
      commit('SET_USER', res.data.user)
      commit('SET_TOKEN', res.data.token)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  logout({commit}){
    commit('SET_USER',null)
    commit('SET_TOKEN',null)
    return true
  },

  async getDoctor({ commit, state }, docId) {
    if (!docId)
      docId = state.userId
    try {
      const res = await axios.get(`/doctors/${docId}`)
      if(res.data.role != "doctor")
        throw new Error('user is not doctor')
      commit('SET_USER', res.data)
      return res.data
    }
    catch (err) {
      commit('SET_USER', null)
      commit('SET_TOKEN', null)
      throw err.response.data.message
    }
  },

  async getRateDoctor({ state }) {
    try {
      const res = await axios.get(`/rates/doctor/${state.userId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getAppointments({ state }) {
    try {
      const res = await axios.get(`/appointments/doctor/${state.userId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getPatients({ state }) {
    try {
      const res = await axios.get(`/patients/doctor/${state.userId}`)
      return res.data.patient
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async updateProfile({ commit, state }, userData) {
    try {
      const res = await axios.put(`/doctors/${state.userId}`, userData)
      commit('SET_USER', res.data)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async updatePassword({ commit, state }, { cpassword, password }) {
    try {
      const res = await axios.post(`/user/changePassword`, { userId: state.userId, cpassword, password })
      commit('SET_USER', res.data.user)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async sendContact({ state }, { subject, message }) {
    console.log(subject);
    try {
      const res = await axios.post(`/contacts/`, { userId: state.userId, subject, message })
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getContacts({ state }) {
    console.log("getting contacts");
    try {
      const res = await axios.get(`/contacts/${state.userId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async acceptAppointment(_, appointmentId) {
    try {
      const res = await axios.put(`/appointments/accept/${appointmentId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async declineAppointment(_, appointmentId) {
    try {
      const res = await axios.put(`/appointments/decline/${appointmentId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getReviews({ state }) {
    try {
      const res = await axios.get(`/reviews/doctor/${state.userId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async deleteReview(_, reviewId) {
    try {
      const res = await axios.delete(`/reviews/${reviewId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async getPatientFile(_, patientId) {
    try {
      const res = await axios.get(`/patientFiles/userId/${patientId}`)
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async updatePatientFile(_, { fileId, appointmentDetail }) {
    try {
      const res = await axios.put(`/patientFiles/${fileId}`, { appointmentDetail })
      return res.data
    }
    catch (err) {
      throw err.response.data.message
    }
  },

  async createRoom() {
    try {
      const res = await axios.post(`/chatRooms/`)
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getChatRooms({ state }) {
    try {
      const res = await axios.get(`/chatRooms/${state.userId}`)
      return res.data.chatRooms
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendMessage(_, message) {
    try {
      const res = await axios.post(`/messages`, { message })
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getMessages(_, roomId) {
    try {
      const res = await axios.get(`/messages/room/${roomId}`)
      return res.data.messages
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendReaction(_, {messageId,reactions}) {
    try {
      const res = await axios.put(`/messages/message/${messageId}`,{reactions})
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },
  
  async updateMessage(_,{messageId,message}){
    try {
      const res = await axios.put(`/messages/message/${messageId}`,message)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async deleteMessage(_,{messageId}){
    try {
      const res = await axios.delete(`/messages/message/${messageId}`)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  }

};
