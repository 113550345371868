import axios from 'axios'
export default {
  async getNotifications({ commit, rootState }) {
    try {
      const res = await axios.get(`/notifications/${rootState.doctor.userId}`)
      console.log(res.data);
      commit("notification/SET_NOTIFS", res.data, {root:true})
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async updateNotification(_, notifId) {
    try {
      const res = await axios.put(`/notifications/${notifId}`)
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },
}