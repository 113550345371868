import Vue from 'vue'
import Vuex from 'vuex'
import doctor from './DoctorModule'
import socket from './SocketModule'
import chat from './ChatModule'
import notification from './NotificationModule'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    doctor,
    socket,
    chat,
    notification
  },
})

