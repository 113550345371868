export default {
  SET_USER(state, user) {
      state.user = user;
      state.userId = (user) ?  user._id :  null;
      if (user){
        localStorage.setItem("algerieDok-U",user._id);
      } 
      else localStorage.removeItem("algerieDok-U");
    },

    SET_TOKEN(state,token){
      state.token = token
      if(token)
        localStorage.setItem('algerieDok-T',token)
      else
      localStorage.removeItem("algerieDok-T");
    },
    
    SET_LANGUAGE(state,lang){
      state.locale = lang
      localStorage.setItem('lang', lang)
    }
};
